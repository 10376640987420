<template>
  <div class="policy">
    <div class="container">
      <h1 class="page-top">
        <div class="title">약관 및 정책</div>
      </h1>
      <div class="row">
        <aside class="col-12 col-lg-3">
          <Lnb/>
        </aside>
        <article class="col-12 col-lg-9">
          <div class="inner">
            <h2>증권형 개인정보처리방침</h2>
            <div class="section">
              <p>
                주식회사 오마이컴퍼니(이하 "당사"라고 합니다)는 고객의
                개인정보보호를 매우 중요시하며, 개인정보보호법,
                정보통신망이용촉진등에관한법률 등 관련법령, 규정을 준수하고
                있습니다.
              </p>
              <p>
                당사는 개인정보처리방침을 통하여 귀하께서 제공하시는 개인정보가
                어떠한 용도와 방식으로 이용되고 있으며 개인정보보호를 위해
                어떠한 조치가 취해지고 있는지 알려드립니다.
              </p>
              <p>
                당사는 개인정보처리방침을 홈페이지 첫 화면에 공개함으로써
                귀하께서 언제나 용이하게 보실 수 있도록 조치하고 있습니다.
                당사의 개인정보처리방침은 정부의 법률 및 지침 변경이나 당사 내부
                방침변경 등으로 수시로 변경될 수 있으며, 개인정보처리방침의
                지속적인 개선을 위하여 필요한 절차를 정하고 있습니다. 개인정보
                보호정책을 개정하는 경우 당사는 그 변경사항을 홈페이지에
                게시하여 이용자들이 개정된 사항을 쉽게 알아볼 수 있도록 하고
                있습니다. 귀하께서는 사이트 방문 시 수시로 확인하시기 바랍니다.
              </p>
            </div>
            <div class="section">
              <br/>
              <h3>제 1 조 개인정보처리방침의 순서</h3>
              <ul class="tight">
                <li>① 처리하는 개인정보의 항목</li>
                <li>② 개인정보의 처리 목적</li>
                <li>③ 개인정보의 제3자 제공</li>
                <li>④ 개인정보 처리의 위탁</li>
                <li>⑤ 개인정보 처리에 대한 동의 및 방법</li>
                <li>⑥ 개인정보의 수집</li>
                <li>⑦ 개인정보의 열람/정정</li>
                <li>⑧ 개인정보의 보유 및 이용기간</li>
                <li>⑨ 동의철회(회원탈퇴)</li>
                <li>⑩ 개인정보의 파기절차 및 방법</li>
                <li>⑪ 법정대리인의 권리</li>
                <li>
                  ⑫ 개인정보 자동 수집 장치의 설치·운영 및 그 거부에 관한 사항
                </li>
                <li>⑬ 개인정보 안정성 확보조치</li>
                <li>⑭ 개인정보침해 관련 상담 및 신고</li>
                <li>⑮ 개인정보 처리방침의 변경에 따른 공지의무</li>
                <li>⑯ 개인정보관리책임자</li>
              </ul>
            </div>
            <div class="section">
              <br/>
              <h3>제 2 조 처리하는 개인정보의 항목</h3>
              <p>
                당사는 회원가입 시 서비스 이용을 위해 필요한 최소한의
                개인정보만을 수집합니다. 관련 법령 또는 규정이 허용하는 범위
                내에서, 귀하가 본 홈페이지에 접속하거나 본 홈페이지를 통해
                당사의 서비스를 이용하는 때에 당사는 다음과 같이 귀하의
                개인정보를 수집할 수 있습니다.
              </p>
              <ul class="tight">
                <li>
                  ① 필수항목: 이름, 휴대폰번호, 주민등록번호, 이메일, 주소
                </li>
                <li>
                  <p>
                    ② 당사는 회원가입한 이용자의 투자서비스 제공을 위해 실명확인
                    및 계좌등록 등을 진행하고 있으며, 아래와 같은 개인정보를
                    수집하고 있습니다.
                  </p>
                  <ul class="list_depth1">
                    <li>
                      1. 소득요건 확인 또는 전문투자자 확인 시(이하 ‘투자자
                      확인’) 필수항목: 소득요건구비 투자자
                      확인서류(금융소득명세서, 근로소득원천징수영수증,
                      온라인소액투자증권 투자내역 확인서, 금융투자전문인력확인서
                      등) 또는 전문투자자 확인서류(전문자격증, 전문투자자 확인증
                      등)
                    </li>
                    <li>
                      <p>2. 실명확인 시 필수항목</p>
                      <ul class="list_depth2">
                        <li>
                          자연인: 성명, 휴대폰번호, 주소, 신분증 사본,
                          주민등록번호
                        </li>
                        <li>
                          외국인: 성명, 휴대폰번호, 주소, 외국인등록번호, 거소증
                        </li>
                        <li>
                          법인: 법인명, 사업자등록번호, 법인등록번호, 대표
                          전화번호, 법인 담당자 전화번호, 주사무소 소재지,
                          법인등기부등본, 대표자 신분증 사본
                        </li>
                      </ul>
                    </li>
                    <li>
                      3. 계좌등록시 필수항목: 본인의 증권계좌번호, 증권사,
                      은행명, 은행계좌번호
                    </li>
                    <li>
                      4. 청약증거금 납입시 필수항목: 은행, 계좌번호,
                      고유식별번호(미성년자의 경우 법정대리인의 실명확인증표,
                      가족관계서류 등 별도 서류)
                    </li>
                    <li>5. 플랫폼 이용기록, IP Address, 쿠키 등</li>
                    <li>
                      6. 금융거래정보: 상품종류, 거래조건, 거래일시, 금액 등
                      거래 설정 및 내역 정보
                    </li>
                  </ul>
                </li>
              </ul>
              <p>
                ※ 귀하는 위와 같은 개인정보의 수집·이용에 거부할 권리가 있으나,
                필수정보 또는 고유식별정보의 수집·이용에 거부할 경우 금융거래
                서비스의 이용에 제약이 있을 수 있습니다.
              </p>
            </div>
            <div class="section">
              <br/>
              <h3>제 3 조 개인정보의 처리 목적</h3>
              <p>
                관련법령 또는 규정이 허용하는 범위에서 당사는 본
                개인정보처리방침 전문에 기재된 목적을 위하여 개인정보를
                수집·이용합니다. 수집하는 구체적인 수집목적 및 이용목적은 다음과
                같습니다.
              </p>
              <ul class="tight">
                <li>
                  ① 회원가입 및 ID발급, 서비스 이용에 따른 본인 확인 절차에서의
                  이용, 서비스이용을 위한 계약의 체결 확인 등
                </li>
                <li>
                  ② 고지사항 전달, 민원·불만처리 등을 위한 원활한 의사소통
                </li>
                <li>
                  ③ 기타 새로운 서비스 정보, 신상품이나 이벤트 정보 등의 안내,
                  서비스 관련 고객상담, 고객정보 관리 및 통계, 감사, 모니터링,
                  금융사고 조사, 기타 법령상 의무이행 등 이벤트 진행 시 배송지의
                  확보
                </li>
              </ul>
            </div>
            <div class="section">
              <br/>
              <h3>제 4 조 개인정보의 제3자 제공</h3>
              <p>
                당사는 귀하의 동의가 있거나 관련 법령의 규정에 의한 경우, 또는
                당사가 귀하에게 제공하는 서비스 또는 상품의 공급을 위하여 필요한
                경우를 제외하고는 본 개인정보 처리방침에서 고지한 범위를 넘어
                귀하의 개인정보를 이용하거나 타인 또는 타기업·기관에 제공하지
                않습니다.
              </p>
              <p>
                귀하의 개인정보를 제3자에게 제공하거나 공유하는 경우에는 사전에
                귀하에게 제공받거나 공유 받는 자가 누구이며 주된 사업이
                무엇인지, 제공 또는 공유되는 개인정보항목이 무엇인지, 개인정보를
                제공하거나 공유하는 목적이 무엇인지 등에 대해 개인정보처리방침
                및 홈페이지 등을 통해 고지한 후 동의를 구합니다.
              </p>
              <table class="omc-table">
                <colgroup>
                  <col style="width: 20%"/>
                  <col style="width: 80%"/>
                </colgroup>
                <tbody>
                <tr>
                  <th>제공받는 자</th>
                  <td>
                    발행인, 한국예탁결제원, 한국엔젤투자협회, 중소벤처기업부,
                    한국증권금융, 신한은행
                  </td>
                </tr>
                <tr>
                  <th>목적</th>
                  <td>
                    자본시장과 금융투자업에 관한 법률 등 관계법령에 따라
                    온라인 소액투자중개업자가 증권발행기업과 한국예탁결제원,
                    한국증권금융, 신한은행 등 예치기관에 제공하는 투자자
                    정보의 제공 및 조회<br/>
                    소득공제를 위한 투자확인서, 엔젤매칭투자 등을 위한
                    투자자정보의 제공 및 조회
                  </td>
                </tr>
                <tr>
                  <th>항목</th>
                  <td>
                    성명, 주민등록번호(사업자번호) 이메일, 휴대폰번호,
                    증권사명, 증권계좌번호, 은행명, 은행계좌번호,
                    투자예치금계좌(가상계좌)번호, 주소, 금융상품의 설정 및
                    내역(상품종류, 거래조건, 거래일시 및 금액)
                  </td>
                </tr>
                <tr>
                  <th>보유 및 이용기간</th>
                  <td>
                    금융실명거래 및 비밀보장에 관한 법률에 따라 5 년 보존 후
                    파기
                  </td>
                </tr>
                </tbody>
              </table>
              <p>
                귀하께서는 당사가 귀하의 개인정보를 이용하거나 제3자에게 제공 ·
                공유한 내역을 요구할 권리가 있으며, 필요 시에 당사의
                개인정보관리책임자에게 전화, 팩스, 이메일 등을 통하여 자료를
                요구할 수 있습니다. 다만, 다음의 경우에는 관련 법령의 규정에
                의하여 귀하의 동의없이 개인정보를 제공할 수도 있으니 이점 양지해
                주시기 바랍니다.
              </p>
              <ul class="tight">
                <li>
                  ① 통계작성 · 학술연구 또는 시장조사를 위하여 필요한 경우로써
                  특정 개인을 알아볼 수 없는 형태로 가공하여 제공하는 경우
                </li>
                <li>
                  ② 금융실명거래 및 비밀보장에 관한 법률, 신용정보의 이용 및
                  보호에 관한 법률, 전기통신사업법, 지방세법, 소비자보호법,
                  한국은행법, 형사소송법 등 법률에 특별한 규정이 있는 경우
                </li>
                <li>
                  ③ 정부기관(준정부기관 포함)이나 법원의 명령에 의하여
                  개인정보를 제공하는 경우
                </li>
              </ul>
            </div>
            <div class="section">
              <br/>
              <h3>제 5 조 개인정보처리의 위탁</h3>
              <p>
                당사는 서비스 향상 및 원활한 계약사항의 이행 등을 위하여
                개인정보 처리업무를 외부 전문업체에 일부 위탁하고 있습니다.
              </p>
              <p>
                당사가 개인정보처리를 위탁하고 있는 수탁사 제공대상자 및
                위탁업무내용, 제공정보는 다음과 같습니다.
              </p>
              <table class="omc-table">
                <colgroup>
                  <col style="width: 30%"/>
                  <col style="width: 30%"/>
                  <col style="width: 40%"/>
                </colgroup>
                <thead>
                <tr>
                  <th>제공 대상자</th>
                  <th>위탁업무내용</th>
                  <th>제공정보</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>나이스평가정보</td>
                  <td>본인확인</td>
                  <td>
                    실명, 생년월일, 성별, 내외국인구분, 이동통신사정보,
                    휴대폰번호
                  </td>
                </tr>
                <tr>
                  <td>신한은행, 한국증권금융</td>
                  <td>
                    청약증거금예치
                    <br/><br/>
                    투자예치금계좌(가상계좌)번호 발급 및 관리
                    <br/><br/>
                    은행계좌 인증
                  </td>
                  <td>
                    [개인회원]<br/>
                    실명, 생년월일, 휴대폰번호, 성별, 청약금액, 은행 계좌번호,
                    투자예치금계좌(가상계좌)번호, 본인확인기관에서 부여하는
                    개인식별정보(CI)<br/>
                    <br/>
                    [법인/조합회원]<br/>
                    법인/조합명, 사업자번호(조합번호), 대표이사 혹은 담당자의
                    실명/ 휴대폰번호/본인확인기관에서 부여하는
                    개인식별정보(CI), 은행계좌번호,
                    투자예치금계좌(가상계좌)번호, 청약금액<br/>
                  </td>
                </tr>
                <tr>
                  <td>한국예탁결제원</td>
                  <td>
                    중앙기록관리<br/>
                    <br/>
                    증권예탁<br/>
                    <br/>
                    전자등록<br/>
                    <br/>
                    보호예수<br/>
                    <br/>
                    증권계좌유효성검증<br/>
                  </td>
                  <td>
                    실명, 주민등록번호, 금융상품의 설정 및 내역정보(상품종류,
                    거래조건, 거래일시 및 금액), 투자자 유형 확인을 위한
                    증빙서류, 주소, 증권사명, 증권계좌번호<br/>
                    <br/>
                  </td>
                </tr>
                <tr>
                  <td>
                    ㈜슬로워크<br/>
                    ㈜엠투넷<br/>
                    ㈜컴홈<br/>
                    카페24㈜<br/>
                    facebook<br/>
                  </td>
                  <td>
                    마케팅<br/>
                    <br/>
                    이메일, SMS,알림톡 발송
                  </td>
                  <td>실명, 닉네임, 이메일, 전화번호</td>
                </tr>
                </tbody>
              </table>
              <p>
                당사는 귀하의 개인정보를 제휴업체에 제공하거나 공유하는 경우에
                사전에 귀하에게 제공받거나 공유 받는 자, 목적, 공유되는
                개인정보항목 등에 대해 개인정보처리방침에 고지한 후 이에 대한
                동의를 구합니다.
              </p>
            </div>
            <div class="section">
              <br/>
              <h3>제 6 조 개인정보 처리에 대한 동의 및 방법</h3>
              <p>
                당사는 귀하의 개인정보 수집 및 이용약관에 대한 동의를 받고
                있습니다.
              </p>
              <p>
                귀하의 개인정보 수집과 관련하여 당사의 개인정보처리방침 또는
                이용약관의 내용에 대해 “동의” 버튼 또는 “취소”버튼을 클릭할 수
                있는 절차를 마련하고 있으며, 귀하가 “동의”버튼을 클릭하면 당사의
                개인정보 수집 및 이용약관에 대해 동의한 것으로 봅니다.
              </p>
            </div>
            <div class="section">
              <br/>
              <h3>제 7 조 개인정보의 수집</h3>
              <p>
                당사는 회원 가입시 서비스 이용을 위해 필요한 최소한의
                개인정보만을 수집합니다.
              </p>
              <p>
                귀하가 당사의 서비스를 이용하기 위해서는 회원 가입시(회원ID,
                비밀번호, 성명, 전화번호, E-mail 주소)를 필수적으로 입력하셔야
                합니다.
              </p>
            </div>
            <div class="section">
              <br/>
              <h3>제 8 조 개인정보의 열람/정정</h3>
              <p>
                귀하는 언제든지 등록되어 있는 귀하의 개인정보를 열람하거나
                정정하실 수 있습니다.
              </p>
              <p>
                개인정보 열람 및 정정을 하고자 할 경우에는 [마이페이지]메뉴를
                통하여 직접 열람 또는 정정 가능합니다.
              </p>
            </div>
            <div class="section">
              <br/>
              <h3>제 9 조 개인정보의 보유 및 이용기간</h3>
              <p>
                당사는 수집한 개인정보의 수집목적 또는 제공받은 목적이 달성된
                경우라도 상법, 증권거래법, 관련세법 등의 관련법류상의 의무이행
                및 기타 감독기관의 요청·명령에 따른 제출할 의무이행에 필요할
                것으로 판단되는 정보에 대해서는 개인정보를 파기하지 아니하고
                제출 등에 활용할 수 있습니다.
              </p>
              <p>
                다만, 위 경우에 해당되지 않을 것으로 판단되는 경우 귀하의
                개인정보를 지체없이 파기합니다.
              </p>
              <ul class="tight">
                <li>
                  ① 회원가입정보의 경우 : 회원가입을 탈퇴하거나 회원에서 제명된
                  때
                </li>
                <li>
                  ② 설문조사, 이벤트 등의 목적을 위하여 수집한 경우 : 당해
                  설문조사, 이벤트 등이 종료한 때
                </li>
              </ul>
            </div>
            <div class="section">
              <br/>
              <h3>제 10 조 동의철회(회원탈퇴)</h3>
              <p>
                회원가입 등을 통해 개인정보의 수집, 이용에 대해 귀하께서
                동의하신 내용을 귀하께서는 언제든지 철회하실 수 있습니다.
              </p>
            </div>
            <div class="section">
              <br/>
              <h3>제 11 조 개인정보의 파기절차 및 방법</h3>
              <p>
                귀하는 회원가입 시 개인정보의 수집·이용 및 제공에 대해 동의한
                내용은 언제든지 철회하실 수 있습니다. 당사는 원칙적으로
                개인정보의 수집 및 이용목적이 달성된 후에는 해당 정보를 지체없이
                파기하되, 귀하가 회원가입 등을 위해 입력하신 정보는 내부 방침 및
                기타 관련법령에 따라 일정기간 저장 후 파기될 수 있습니다.
              </p>
              <p>
                이때 일정기간 저장된 개인정보는 법률에 의한 경우가 아니고서는
                보유 되어지는 이외의 다른 목적으로 이용되지 않습니다.
              </p>
              <p>
                파기 방법은 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을
                통하여 파기하고, 전자적 파일형태로 저장된 개인정보는 기록을
                재생할 수 없는 기술적 방법을 사용하여 삭제합니다.
              </p>
            </div>
            <div class="section">
              <br/>
              <h3>제 12 조 법정대리인의 권리</h3>
              <p>
                만14세 미만의 아동으로부터 개인정보를 수집하거나 제3자에게
                아동의 개인정보를 제공하고자 하는 경우에는 그 법정대리인의
                동의를 구해야 합니다.
              </p>
              <p>
                이 경우 당사는 그 아동에게 법정대리인의 동의를 얻기 위하여
                필요한 법정대리인의 성명 등의 정보를 요구할 수 있습니다.
                법정대리인은 상기 아동의 개인정보에 대한 동의를 철회할 수
                있으며, 당해 아동이 제공한 개인정보에 대한 열람 또는 오류의
                정정을 요구할 수 있습니다.
              </p>
              <p>
                당사는 법정대리인의 동의없이 아동에 관한 정보를 제3자에게
                제공하거나 공유하지 않으며, 아동으로부터 수집한 개인정보에
                대하여 법정대리인이 오류의 정정을 요구하는 경우 그 오류를 정정할
                때까지 해당 개인정보의 이용 및 제공을 금지합니다.
              </p>
            </div>
            <div class="section">
              <br/>
              <h3>
                제 13 조 개인정보 자동 수집 장치의 설치·운영 및 그 거부에 관한
                사항
              </h3>
              <p>
                당사는 고객의 사용편의를 위하여 '쿠키(cookie)'를 사용합니다.
                귀하는 쿠키에 대한 선택권이 있습니다. 따라서 귀하는
                웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나 쿠키가
                저장될 때마다 확인을 거치거나 아니면 모든 쿠키의 저장을 거부할
                수도 있습니다. 다만, 고객님께서 쿠키설치를 거부하셨을 경우,
                당사의 일부 서비스를 제공받지 못하는 경우가 발생할 수 있음을
                알려드립니다.
              </p>
            </div>
            <div class="section">
              <br/>
              <h3>제 14 조 개인정보 안정성 확보조치</h3>
              <p>
                당사는 귀하의 개인정보가 분실·도난·유출·변조 또는 훼손되지
                아니하도록 내부 관리계획 수립, 접속기록 보관 등 안전성 확보에
                필요한 기술적·관리적 및 물리적 조치를 다음과 같이 하고 있습니다.
              </p>
              <ul class="tight">
                <li>
                  ① 고객의 개인정보는 비밀번호에 의해 보호되며, 파일 및 전송
                  데이터를 암호화하거나 파일 잠금기능(Lock)을 사용하여 중요한
                  데이터는 별도의 보안기능을 통해 보호되고 있습니다.
                </li>
                <li>
                  ② 당사는 암호알고리즘을 이용하여 네트워크 상의 개인정보를
                  안전하게 전송할 수 있는 보안장치(SSL 또는 SET)를 채택하고
                  있습니다.
                </li>
                <li>
                  ③ 해킹 등에 의해 고객의 개인정보가 유출되는 것을 방지하기
                  위해, 외부로부터의 침입을 차단하는 장치를 이용하고 있으며, 각
                  서버마다 침입탐지시스템을 설치하여 24시간 침입을 감시하고
                  있습니다.
                </li>
              </ul>
            </div>
            <div class="section">
              <br/>
              <h3>제 15 조 개인정보침해 관련 상담 및 신고</h3>
              <p>
                개인정보침해에 대한 신고·상담이 필요하신 경우에는 정보통신부
                산하 공공기관인 한국정보보호진흥원(KISA)내
                개인정보침해신고센터로 문의하시기 바랍니다.
              </p>
              <p>개인정보침해신고센터</p>
              <p>전화: (국번없이) 118</p>
              <p>홈페이지: https://privacy.kisa.or.kr</p>
            </div>
            <div class="section">
              <br/>
              <h3>제16조 개인정보 처리방침의 변경에 따른 공지의무</h3>
              <p>
                본 개인정보처리방침은 2021년 4월 26일에 개정되었으며 법령·정책
                또는 보안기술의 변경에 따라 내용의 추가·삭제 및 수정이 있을
                시에는 변경되는 개인정보처리방침을 시행하기 전에 변경 이유 및
                내용 등을 공지하도록 하겠습니다.
              </p>
            </div>
            <div class="section">
              <br/>
              <h3>개인정보관리책임자</h3>
              <p>성명: 한송이</p>
              <p>직위: 대표</p>
              <p>E-mail: zenovefa@ohmycompany.com</p>
              <p>전화: 02)388-2556</p>
              <p>Fax: 0505-300-2556</p>
            </div>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from "@vue/composition-api";
import Lnb from "./Lnb.vue";
import mixin from "@/scripts/mixin";

function Component(initialize) {
  this.name = "pagePolicyInvestPrivacy";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {Lnb},
  setup() {
    const component = new Component(() => {
    });

    return {component};
  },
});
</script>

<style lang="scss" scoped>
@import "../../styles/page.policy";
</style>